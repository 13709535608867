import {IonBackButton, IonCol, IonButtons, IonContent, IonHeader, IonRouterLink, IonPage, IonRow, IonTitle, IonToolbar, IonBadge} from '@ionic/react';
import {useSelector} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {IKImage} from 'imagekitio-react';
import {imageConverterBasePath, imagekitURL} from 'imagekitConfig';


const ResultsPage = () => {
  // To avoid showing blank `results` page, redirect user back to `unit-search` page if Redux does not contain an array of results
  const {results} = useSelector(state => state.search);
  if (results.length === 0) return <Redirect to='/my/unit-search' />;

  return (
    <IonPage>
      <IonHeader className='ion-no-border'>
        <IonToolbar className='result-toolbar'>
          <IonButtons slot='start'>
            <IonBackButton className='back-btn' color='light' mode='ios' text='' defaultHref='/my/unit-search' />
          </IonButtons>
          <IonTitle>Search Results</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className='search-results ion-padding'>
        <IonRow>
          {results.map(result => (
            <IonCol size='6' key={result.DocId}>
              <div className='holder' >
                <IonRouterLink className='routerr' routerLink={`/my/unit-search/results/unit-details/${result.DocId}`}>
                </IonRouterLink>
                <div className='back-holder'>
                  {result.ImagesPrimary ? <IKImage {...imageProps} path={`${imageConverterBasePath}${result.ImagesPrimary}`} /> : <img src='assets/imgs/place.png' />}
                </div>
                <div className='detail-holder'>
                  <h3>{result.Manufacturer}</h3>
                  <div className='type-holder'>
                    <h5>{result.InventoryDescription}</h5>
                  </div>
                  <IonBadge>
                ${result.UnitValue}
                  </IonBadge>
                </div>
              </div>
            </IonCol>
          ))}
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

const imageProps = {
  style: {width: '100%', height: 'auto'},
  urlEndpoint: imagekitURL,
  loading: 'lazy',
  lqip: {active: true},
  transformation: [{height: 450, width: 675}],
};
export default ResultsPage;
