import {useHistory} from 'react-router-dom';
import {IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonList, IonPage, IonText, IonToast, IonToolbar} from '@ionic/react';
import {close as closeIcon, chevronBack} from 'ionicons/icons';
import {FormikForm, SubmitButton, TextField} from 'components';
import {object, string} from 'yup';
import {Auth} from 'firebaseConfig';

const ForgotPasswordPage = () => {
  const history = useHistory();

  return (
    <IonPage>
      <IonHeader className='ion-no-border'>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonBackButton className='back-btn' color='dark' mode='ios' text='' icon={chevronBack} defaultHref='/auth/login' />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className='ion-padding'>
        <IonText className='the-title'>
          <h1>Password Recovery</h1>
        </IonText>
        <IonText className='description'>
          <h6>
            Please enter your email below. If connected to an existing account, we will email you a link to reset your password.
          </h6>
        </IonText>
        <FormikForm {...formProps(history)}>{({status, isSubmitting}) => (<>
          <IonList lines='full'>
            <TextField required name='Email' ionInputProps={{inputmode: 'email'}} />
          </IonList>
          <SubmitButton expand='block'>Reset</SubmitButton>
          <IonButton className='cancel-btn' expand='block' fill='clear' onClick={() => history.goBack()} disabled={isSubmitting}>
            Cancel
          </IonButton>
          <IonToast
            color={status.color}
            duration={status.duration}
            isOpen={status.isOpen}
            message={status.message}
            onDidDismiss={status.onDidDismiss}
            buttons={[{side: 'end', icon: closeIcon, role: 'cancel'}]}
          />
        </>)}
        </FormikForm>
      </IonContent>
    </IonPage>
  );
};

const initialStatus = {color: '', duration: 0, isOpen: false, message: '', onDidDismiss: () => {}};
const formProps = (history) => ({
  initialStatus,
  initialValues: {Email: ''},
  validationSchema: object().shape({Email: string().email().required()}),
  onSubmit: (values, actions) => {
    (async () => {
      try {
        await Auth.sendPasswordResetEmail(values.Email);
        const message = 'Password reset email has been sent.';
        const onDidDismiss = () => {
          actions.setStatus(initialStatus);
          history.push('/auth/login');
          actions.setSubmitting(false);
        };
        actions.setStatus({color: 'success', duration: 5000, isOpen: true, message, onDidDismiss});
      }
      catch (error) {
        const message = (() => {
          if (error.code === 'auth/missing-email') return 'No email provided in form.';
          else if (error.code === 'auth/invalid-email') return 'Invalid email provided to form.';
          else if (error.code === 'auth/too-many-requests') return 'Too many reset requests. Try again later.';
          else if (error.code === 'auth/user-not-found') return 'No user found with this email address.';
          else return `An error occured: ${error?.code || error.message}`;
        })();
        const onDidDismiss = () => {
          actions.setStatus(initialStatus);
          actions.setSubmitting(false);
        };
        actions.setStatus({color: 'danger', duration: 5000, isOpen: true, message, onDidDismiss});
      }
    })();
  },
});
export default ForgotPasswordPage;
