import {useEffect, useState} from 'react';
import {IonContent, IonPage, IonGrid, IonRow, IonText} from '@ionic/react';
import {useHistory} from 'react-router-dom';

const RequestReceivedPage = () => {
  const [timer, setTimer] = useState(10);
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      if (timer > 0) setTimer(timer - 1);
      else history.replace('/auth/login');
    }, 1000);
  }, [history, timer]);
  return (
    <IonPage>
      <IonContent className='ion-padding'>
        <IonGrid style={{height: '100%'}}>
          <IonRow className='ion-justify-content-center ion-align-items-center' style={{height: '100%'}}>
            <div className='holder ion-text-center'>
              <div className='img-holder ion-text-center'>
                <img src='assets/imgs/check.svg' alt='Success logo' />
              </div>
              <IonText className='main-title'>
                <h3>Thanks for the submission</h3>
              </IonText>
              <IonText className='description'>
                <h6>Your request has been received and one of our reps will reach out soon to grant access.</h6>
              </IonText>
              <IonText>
                {/* <p>Redirect to login page in {timer} seconds</p> */}
              </IonText>
            </div>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default RequestReceivedPage;
