import {IonButton, IonImg, IonRouterLink} from '@ionic/react';
import PropTypes from 'prop-types';
import {Functions} from 'firebaseConfig';
const usdFormatter = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'});

const ClosedRatings = ({ratings}) => {
  if (ratings.length === 0) return null;
  const handleDelete = async (rating) => {
    await Functions.httpsCallable('ratingDeleteRequest')({rating});
  };

  return (<>
    {ratings.map(rating => {
      const openDate = new Date(rating.Submitted.On);
      const closeDate = new Date(rating.Closed.On);
      return (
        <div style={styles.border} key={rating.DocId}>
          <ul>
            <li>Submitted: {openDate.toLocaleString('en-US')}</li>
            <li>Closed: {closeDate.toLocaleString('en-US')}</li>
            <li>Closed By: {rating.Closed.By}</li>
            <li>Quote: {usdFormatter.format(rating.Quote)}</li>
            <li>Visual Details: {rating.Details}</li>
            <li>
              <IonRouterLink routerLink={`/my/photo-rating/details/${rating.DocId}`}>Details</IonRouterLink>
            </li>
          </ul>
          <IonImg src={rating.Images[0].ThumbnailUrl} />
          <IonButton size='small' onClick={() => handleDelete(rating)}>Delete_Dev</IonButton>
        </div>
      );
    })}
  </>);
};

const styles = {
  border: {border: '1px solid gray'},
};
ClosedRatings.propTypes = {ratings: PropTypes.array.isRequired};
export default ClosedRatings;
