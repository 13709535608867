import React from 'react';
import ReactDOM from 'react-dom';
import {IonApp, setupIonicReact} from '@ionic/react';
import {Route} from 'react-router-dom';
import {IonReactRouter} from '@ionic/react-router';
import {SplashScreen} from '@capacitor/splash-screen';
import {defineCustomElements} from '@ionic/pwa-elements/loader';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

// Import Redux Elements
import {Provider as ReduxProvider} from 'react-redux';
import store from './state/store';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables and Animate.css */
import './theme/variables.css';
import 'animate.css';

// Placeholder to add custom ionic config object; https://ionicframework.com/docs/react/config
// Mode set to 'md' to apply Material Design to entire application, even on iOS devices.
setupIonicReact({mode: 'md'});

ReactDOM.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <IonApp>
        <IonReactRouter>
          <Route path='/' component={App} />
        </IonReactRouter>
      </IonApp>
    </ReduxProvider>
  </React.StrictMode>,
  document.getElementById('root'),
  () => {
    defineCustomElements(window);
    SplashScreen.hide();
  },
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
