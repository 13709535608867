import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {IonBackButton, IonButtons, IonCol, IonContent, IonHeader, IonImg, IonPage, IonRow, IonSpinner, IonText, IonTitle, IonToast, IonToolbar} from '@ionic/react';
import {close as closeIcon, chevronBack} from 'ionicons/icons';
import {Firestore} from 'firebaseConfig';
import {useHistory} from 'react-router-dom';
const usdFormatter = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'});

const RatingDetails = () => {
  const [toast, setToast] = useState(initialToast);
  const [rating, setRating] = useState({loading: true, data: null});
  const history = useHistory();
  const {DocId} = useParams();

  useEffect(() => {
    Firestore.getDoc(Firestore.doc(`Photo-Ratings/${DocId}`)).then(doc => {
      if (!doc.exists()) throw new Error('Photo rating could not be found.');
      setRating({data: {...doc.data(), DocId: doc.id}, error: null, loading: false});
    }).catch(error => {
      const onDidDismiss = () => {
        history.replace('/my/photo-rating');
        setToast(initialToast);
      };
      setToast({color: 'danger', duration: 5000, isOpen: true, message: error.message, onDidDismiss});
    });
  }, [DocId, history]);

  const openDate = rating?.data?.Submitted?.On ? new Date(rating.data.Submitted.On.toMillis()) : null;
  const closeDate = rating?.data?.Closed?.On ? new Date(rating.data.Submitted.On.toMillis()) : null;
  return (
    <IonPage>
      <IonHeader className='ion-no-border'>
        <IonToolbar className='rating-toolbar'>
          <IonTitle>Rating Details</IonTitle>
          <IonButtons slot='start'>
            <IonBackButton className='back-btn' color='light' mode='ios' text='' icon={chevronBack} defaultHref='/my/photo-rating' />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className='ion-padding'>
        {(rating.loading || rating.error) ? (
          <IonSpinner name='dots' style={{width: '100%', marginTop: '50vh', transform: 'scale(2)'}} />
        ) : (<>
          <IonRow>
            {rating?.data?.Images && rating.data.Images.length > 0 && rating.data.Images.map((image, index) => (
              <IonCol size='6' key={image.FileId}>
                <IonImg src={image.ThumbnailUrl} />
              </IonCol>
            ))}
          </IonRow>
          <IonText>
            <h2>{rating.data.Status}</h2>
          </IonText>
          <IonText>
            <h2>Submitted: {openDate.toLocaleString('en-US')}</h2>
          </IonText>
          {rating.data.Closed && (
            <div style={{border: '1px solid gray'}}>
              <IonText>
                <h5>Quote: {usdFormatter.format(rating.data.Quote)}</h5>
              </IonText>
              <IonText>
                <h5>Closed By: {rating.data.Closed.By}</h5>
              </IonText>
              <IonText>
                <h5>Closed On: {closeDate.toLocaleString('en-US')}</h5>
              </IonText>
            </div>
          )}

        </>)}
        <IonToast
          color={toast?.color}
          duration={toast?.duration}
          isOpen={toast?.isOpen}
          message={toast?.message}
          onDidDismiss={toast?.onDidDismiss}
          buttons={[{side: 'end', icon: closeIcon, role: 'cancel'}]}
        />
      </IonContent>
    </IonPage>
  );
};

const initialToast = {color: 'danger', duration: 5000, isOpen: false, message: '', onDidDismiss: () => {}};
export default RatingDetails;
