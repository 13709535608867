import {IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonList, IonPage, IonText, IonToast, IonToolbar} from '@ionic/react';
import {useHistory} from 'react-router-dom';
import {close as closeIcon, chevronBack} from 'ionicons/icons';
import {FormikForm, SubmitButton, TextField} from 'components';
import {object, string} from 'yup';
import {Firestore} from 'firebaseConfig';

const RequestAccountPage = () => {
  const history = useHistory();
  return (
    <IonPage>
      <IonHeader className='ion-no-border'>
        <IonToolbar >
          <IonButtons slot='start'>
            <IonBackButton icon={chevronBack} defaultHref='/auth/login' />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className='ion-padding'>
        <IonText className='the-title'>
          <h1>Request Account</h1>
        </IonText>
        <IonText className='description'>
          <h6>
            Please enter all info below and one of our reps will be in touch shortly to setup your account.
          </h6>
        </IonText>
        <FormikForm {...formProps(history)}>{({isSubmitting, status}) => (<>
          <IonList lines='full'>
            <TextField required name='FirstName' />
            <TextField required name='LastName' />
            <TextField required name='Email' ionInputProps={{inputmode: 'email'}} />
            <TextField required name='Phone' />
          </IonList>
          <SubmitButton expand='block' />
          <IonButton className='cancel-btn' expand='block' fill='clear' onClick={() => history.goBack()} disabled={isSubmitting}>
            Cancel
          </IonButton>
          <IonToast
            color={status?.color}
            duration={status?.duration}
            isOpen={status?.isOpen}
            message={status?.message}
            onDidDismiss={status?.onDidDismiss}
            buttons={[{side: 'end', icon: closeIcon, role: 'cancel'}]}
          />
        </>)}
        </FormikForm>
      </IonContent>
    </IonPage>
  );
};

const initialStatus = {color: '', duration: 0, isOpen: false, message: '', onDidDismiss: () => {}};
const formProps = (history) => ({
  initialStatus,
  initialValues: {
    FirstName: 'John',
    LastName: 'Smith',
    Email: 'john@email.com',
    Phone: '555-555-5555',
  },
  validationSchema: object().shape({
    FirstName: string().label('First Name').required(),
    LastName: string().label('Last Name').required(),
    Email: string().email().required(),
    Phone: string().required(),
  }),
  onSubmit: async (values, actions) => {
    try {
      const {FirstName, LastName, Email, Phone} = values;
      const emailDoc = {
        to: ['daniel@kbirecycling.com'],
        message: {
          subject: 'Catbuyer - Account Request',
          text: `${FirstName} ${LastName} has requested an account. Email: ${Email}, Phone: ${Phone}`,
          html: `${FirstName} ${LastName} has requested an account. Email: ${Email}, Phone: ${Phone}`,
        },
      };
      const collection = Firestore.collection('Emails');
      await Firestore.addDoc(collection, emailDoc);
      history.replace('/auth/request-received');
    }
    catch (error) {
      const message = error.message;
      const onDidDismiss = () => {
        actions.setStatus(initialStatus);
        actions.setSubmitting(false);
      };
      actions.setStatus({color: 'danger', duration: 5000, isOpen: true, message, onDidDismiss});
    }
  },
});
export default RequestAccountPage;
