import {useMemo} from 'react';
import {IonBackButton, IonRow, IonCol, IonButtons, IonContent, IonGrid, IonHeader, IonPage, IonSlide, IonSlides, IonText, IonTitle, IonToolbar} from '@ionic/react';
import {chevronBack} from 'ionicons/icons';
import {useSelector} from 'react-redux';
import {Redirect, useParams} from 'react-router-dom';
import {IKImage} from 'imagekitio-react';
import {imageConverterBasePath, imagekitURL} from 'imagekitConfig';
import PriceRange from './PriceRange';

const UnitDetailsPage = () => {
  const params = useParams();
  const {results} = useSelector(state => state.search);
  const result = useMemo(() => {
    // if no results, then return undefined to redirect user back to main `unit-search` page
    if (!results || results.length === 0) return undefined;
    // return result that matches `params.DocId` or undefined if no match is found
    return results.find(result => params.DocId === result.DocId);
  }, [params, results]);

  if (result === undefined) return <Redirect to='/my/unit-search' />;
  return (
    <IonPage>
      <IonHeader className='ion-no-border'>
        <IonToolbar className='unit-detail-toolbar'>
          <IonButtons slot='start'>
            <IonBackButton className='back-btn' color='light' mode='ios' text='' icon={chevronBack} defaultHref={'/my/unit-search'} />
          </IonButtons>
          <IonTitle>Unit Details</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className='unit-detail ion-padding'>
        <IonSlides>
          {result.ImagesList.map(image => (
            <IonSlide key={image.Name}>
              <IonGrid>
                <IonRow>
                  {image ? <IonCol size="12">
                    <IKImage {...imageProps} path={`${imageConverterBasePath}${image.Name}`} />
                  </IonCol> : <IonCol size="12">
                    <img src='assets/imgs/place.png' />
                  </IonCol>}
                </IonRow>
              </IonGrid>
            </IonSlide>
          ))}
        </IonSlides>
        <IonText>
          <h3>{result.UnitId}</h3>
        </IonText>
        <IonText>
          <h4>{result.InventoryDescription}</h4>
        </IonText>
        <PriceRange result={result} />
      </IonContent>
    </IonPage>
  );
};

const imageProps = {
  style: {width: '100%', height: 'auto'},
  urlEndpoint: imagekitURL,
  loading: 'lazy',
  lqip: {active: true},
  transformation: [{height: 450, width: 675}],
};
export default UnitDetailsPage;
