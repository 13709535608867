import {useState} from 'react';
import {Camera, CameraResultType, CameraSource} from '@capacitor/camera';

const usePhotoGallery = () => {
  const [photos, setPhotos] = useState([]);

  const takePhoto = async () => {
    const photo = await Camera.getPhoto({
      allowEditing: true,
      preserveAspectRatio: true,
      resultType: CameraResultType.Base64,
      source: CameraSource.Camera,
      quality: 75,
    });

    const newPhoto = {
      fileName: `${new Date().getTime()}.${photo.format}`,
      format: photo.format,
      base64Src: `data:image/${photo.format};base64, ${photo.base64String}`,
      base64String: photo.base64String,
    };
    setPhotos([...photos, newPhoto]);
  };

  const deletePhoto = (index) => {
    const newPhotos = [...photos];
    newPhotos.splice(index, 1);
    setPhotos(newPhotos);
  };

  const clearPhotos = () => {
    setPhotos([]);
  };

  return {
    photos,
    takePhoto,
    deletePhoto,
    clearPhotos,
  };
};

export default usePhotoGallery;
