import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  authLoading: true,
  currentUser: null,
  signoutPending: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthLoading: (state, action) => {
      state.authLoading = action.payload;
    },
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },
    setSignoutPending: (state, action) => {
      state.signoutPending = action.payload;
    },
  },
});

export const authActions = authSlice.actions;

export default authSlice.reducer;
