import {IonAvatar, IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonItem, IonLabel, IonList, IonPage, IonTitle, IonToolbar} from '@ionic/react';
import {useAuthSignOut} from 'hooks';
import {chevronBack} from 'ionicons/icons';

const SettingsPage = () => {
  const authSignOut = useAuthSignOut();

  return (
    <IonPage>
      <IonHeader className='ion-no-border'>
        <IonToolbar className='settings-toolbar'>
          <IonButtons slot='start'>
            <IonBackButton color='light' icon={chevronBack} defaultHref='/my/home' />
          </IonButtons>
          <IonTitle>Settings</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className='settings ion-padding'>
        <div className='profie-holder ion-text-center'>
          <IonAvatar>
            <img src='assets/imgs/avatar.png' alt='User avatar' />
          </IonAvatar>
          <h2>Jason Smith</h2>
          <div className='btnn-holder'>
            <IonButton>Profile Settings</IonButton>
          </div>
        </div>
        <IonList>
          <IonItem detail button routerLink='/my/home/settings/update-password'>
            <div className='thumb flex al-center jc-center' slot='start'>
              <img src='assets/imgs/tabicn/changepass.svg' alt='Password icon' />
            </div>
            <IonLabel>Update Password</IonLabel>
          </IonItem>
          <IonItem detail button routerLink='/my/home/settings/adjust-margin'>
            <div className='thumb flex al-center jc-center' slot='start'>
              <img src='assets/imgs/tabicn/adjustmargin.svg' alt='Adjust margin icon' />
            </div>
            <IonLabel>Adjust Margins</IonLabel>
          </IonItem>
          <IonItem detail button routerLink='/my/home/settings/need-help'>
            <div className='thumb flex al-center jc-center' slot='start'>
              <img src='assets/imgs/help.svg' alt='Help icon' />
            </div>
            <IonLabel>Need Help</IonLabel>
          </IonItem>
        </IonList>
        <div className='bottom-holder ion-text-center'>
          <IonButton expand='block' color='dark' fill='outline' onClick={authSignOut}>Logout</IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default SettingsPage;
