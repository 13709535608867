import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {IonContent, IonHeader, IonList, IonPage, IonSelectOption, IonText, IonTitle, IonToast, IonToolbar} from '@ionic/react';
import {close as closeIcon} from 'ionicons/icons';
import {object, string} from 'yup';
import {FormikForm, SelectField, SubmitButton, TextField} from 'components';
import {useSearch} from 'hooks';
import {Functions} from 'firebaseConfig';

const SearchPage = () => {
  const history = useHistory();
  const {leaseRate, metalValue} = useSelector(state => state.market);
  const {tier: supplierTier} = useSelector(state => state.supplier);
  const {setResults} = useSearch();
  const formProps = useMemo(() => ({
    initialStatus: '',
    initialValues: {
      UnitId: 'GD',
      Manufacturer: '',
    },
    validationSchema: object().shape({
      UnitId: string().required(),
      Manufacturer: string().notRequired(),
    }),
    onSubmit: (values, actions) => {
      (async () => {
        try {
          const results = await Functions.httpsCallable('typesenseUnitSearch')({values, leaseRate, metalValue, supplierTier});

          // Trigger IonToast message if no results returned from typsense query and return to prevent remainder of code from running
          if (results.data.length === 0) {
            const message = 'Search did not return any results.';
            const onDidDismiss = () => {
              actions.setStatus('');
              actions.setSubmitting(false);
            };
            actions.setStatus({color: 'secondary', duration: 5000, isOpen: true, message, onDidDismiss});
            return;
          }

          // Set typsense results to redux and reset form for when user navigates back to perform new search.
          setResults(results.data);
          history.push('/my/unit-search/results');
          setTimeout(() => {
            actions.setStatus('');
            actions.setSubmitting(false);
          }, 1000);
        }
        catch (error) {
          const message = error.message;
          const onDidDismiss = () => actions.setStatus('');
          actions.setStatus({color: 'danger', duration: 5000, isOpen: true, message, onDidDismiss});
        }
      })();
    },
  }), [history, leaseRate, metalValue, setResults, supplierTier]);

  return (
    <IonPage>
      <IonHeader className='ion-no-border'>
        <IonToolbar className='search-page-toolbar'>
          <IonTitle>Unit Search</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className='search-page ion-padding'>
        <IonText className='description'>
          <h4>Here you will find our best prices for converters</h4>
        </IonText>
        <IonText>
          <h6>Search by Unit ID and/or Manufacturer</h6>
        </IonText>
        <FormikForm {...formProps}>{({status}) => (<>
          <IonList lines='full'>
            <TextField required name='UnitId' label='Unit ID' />
            <SelectField name='Manufacturer'>
              <IonSelectOption value='Audi'>Audi</IonSelectOption>
              <IonSelectOption value='Bentley'>Bentley</IonSelectOption>
              <IonSelectOption value='Toyota'>Toyota</IonSelectOption>
            </SelectField>
          </IonList>
          <SubmitButton expand='block'>Search</SubmitButton>
          <IonToast
            color={status?.color}
            duration={status?.duration}
            isOpen={status?.isOpen}
            message={status?.message}
            onDidDismiss={status?.onDidDismiss}
            buttons={[{side: 'end', icon: closeIcon, role: 'cancel'}]}
          />
        </>)}
        </FormikForm>
      </IonContent>
    </IonPage>
  );
};

export default SearchPage;
