import {IonCol, IonIcon, IonRow} from '@ionic/react';
import {camera as cameraIcon} from 'ionicons/icons';
import PropTypes from 'prop-types';


const PhotoPlaceholder = ({photos}) => {
  if (photos.length !== 0) return null;
  return (
    <IonRow>
      <IonCol size='12' style={{display: 'flex', justifyContent: 'center'}}>
        <IonIcon icon={cameraIcon} style={{fontSize: '8rem'}} />
      </IonCol>
    </IonRow>
  );
};

PhotoPlaceholder.propTypes = {photos: PropTypes.array.isRequired};
export default PhotoPlaceholder;
