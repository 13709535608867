import {useCallback, useEffect} from 'react';
import {batch, useDispatch, useSelector} from 'react-redux';
import {Functions} from 'firebaseConfig';
import {supplierActions} from 'state/supplier/slice';
const ONE_HOUR_MS = 3600000;
const TWENTY_FOUR_HOURS_MS = ONE_HOUR_MS * 24;

const useSupplier = (currentUser) => {
  const {individual, business} = useSelector(state => state.supplier);
  const dispatch = useDispatch();

  const getSupplier = useCallback(async (uid) => {
    // Get user's individual supplier doc from kbi-catalyst project;
    const {data: individual} = await Functions.httpsCallable('supplierGetIndividual')({uid});
    dispatch(supplierActions.setIndividual({pending: false, data: individual}));

    // Determine whether individual is linked to a business
    const hasLinkedBusiness = Boolean(individual?.['@Supplier-Businesses']?.DocId);

    if (!hasLinkedBusiness) {
      batch(() => {
        // If no linked business, set redux business data to null and pending false to turn off <PageLoader />
        dispatch(supplierActions.setBusiness({pending: false, data: null}));
        // Set individual's tier to the redux's supplier tier; Will be used to price converters;
        dispatch(supplierActions.setTier(individual?.['@Admin-Supplier-Tiers']));
      });
    }
    else if (hasLinkedBusiness) {
      // Get linked business Firestore doc and then set to redux's supplier business
      const {data: business} = await Functions.httpsCallable('supplierGetBusiness')({DocId: individual?.['@Supplier-Businesses'].DocId});
      batch(() => {
        dispatch(supplierActions.setBusiness({pending: false, data: business}));
        // Set business' tier to the redux's supplier tier; Will be used to price converters;
        dispatch(supplierActions.setTier(business?.['@Admin-Supplier-Tiers']));
      });
    }
  }, [dispatch]);

  useEffect(() => {
    if (currentUser?.uid) {
      // Get supplier values as soon as App mounts
      getSupplier(currentUser.uid);
      // Refresh supplier values after twenty-four hours
      setInterval(() => getSupplier(currentUser.uid), TWENTY_FOUR_HOURS_MS);
    }
  }, [currentUser?.uid, getSupplier]);

  // Supplier is no longer loading when both individual and business values are no longer pending
  const supplierLoading = !Boolean(!individual.pending && !business.pending);

  return {supplierLoading};
};

export default useSupplier;
