import {useState} from 'react';
import {IonBackButton, IonButtons, IonContent, IonHeader, IonIcon, IonLabel, IonList, IonPage, IonTitle, IonToolbar} from '@ionic/react';
import {IonItem, IonItemOption, IonItemOptions, IonItemSliding} from '@ionic/react';
import {chevronBack, notificationsOutline as notificationsIcon} from 'ionicons/icons';
const sampleNotificationData = [
  {
    id: 4,
    title: 'Notification 4',
    message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    isRead: false,
    timestamp: '5/5/22 10:19 am',
  },
  {
    id: 3,
    title: 'Notification 3',
    message: 'Praesent pharetra pellentesque ex, malesuada fermentum felis tempus hendrerit.',
    isRead: false,
    timestamp: '5/1/22 8:24 am',
  },
  {
    id: 2,
    title: 'Notification 2',
    message: 'Nunc lacinia luctus porttitor.',
    isRead: true,
    timestamp: '4/13/22 1:58 pm',
  },
  {
    id: 1,
    title: 'Notification 1',
    message: 'Curabitur sodales at tellus eu molestie.',
    isRead: true,
    timestamp: '3/18/22 11:29 am',
  },
];

const NotificationsPage = () => {
  const [notifications, setNotification] = useState(sampleNotificationData);
  const handleMarkAsRead = (readId) => {
    const newNotifications = notifications.map(notification => {
      if (notification.id === readId) return {...notification, isRead: true};
      else return notification;
    });
    setNotification(newNotifications);
  };
  const handleDelete = (deletedId) => {
    const newNotifications = notifications.filter(notification => notification.id !== deletedId);
    setNotification(newNotifications);
  };

  return (
    <IonPage>
      <IonHeader className='ion-no-border'>
        <IonToolbar className='notification-toolbar'>
          <IonButtons slot='start'>
            <IonBackButton className='back-btn' color='light' mode='ios' text='' icon={chevronBack} defaultHref='/my/home' />
          </IonButtons>
          <IonTitle>Notifications</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className='ion-padding'>
        <IonList className='animate__animated animate__slideInUp'>
          {notifications.map(notification => (
            <IonItemSliding key={notification.id}>
              <IonItem className='notifi-item'>
                <div className='notifi-avatar flex al-center jc-center' slot='start'>
                  <IonIcon icon={notificationsIcon} />
                  <div className='notifi-dot'>
                  </div>
                </div>
                <IonLabel>
                  {notification.isRead && <h2>{notification?.title || 'Notice'}</h2>}
                  {!notification.isRead && <h3>{notification?.title || 'Notice'}</h3>}
                  <h6 style={{whiteSpace: 'break-spaces'}}>{notification?.message || ''}</h6>
                </IonLabel>
                <div className='end-slot' slot='end'>
                  <p>03:45 pm</p>
                </div>
              </IonItem>
              <IonItemOptions side='end'>
                <IonItemOption onClick={() => handleMarkAsRead(notification.id)}>Mark as Read</IonItemOption>
                <IonItemOption onClick={() => handleDelete(notification.id)}>Delete</IonItemOption>
              </IonItemOptions>
            </IonItemSliding>
          ))}
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default NotificationsPage;
