import React from 'react';
import {IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonText} from '@ionic/react';
import PropTypes from 'prop-types';
const usdFormatter = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'});

const DifferenceIndicator = ({metal}) => {
  const difference = metal.current.market - metal.previous.market;
  if (difference > 0) return <h6 style={{color: 'green'}}>{'\u2191'} {usdFormatter.format(difference)}</h6>;
  else if (difference < 0) return <h6 style={{color: 'red'}}>{'\u2193'} {usdFormatter.format(difference)}</h6>;
  return <h6>{usdFormatter.format(difference)}</h6>;
};
DifferenceIndicator.propTypes = {metal: PropTypes.object.isRequired};


const MetalCard = ({metal}) => {
  return (
    <IonCard className='metal-card'>
      <IonCardHeader>
        <IonCardSubtitle className='flex al-center'>
          <div className='avatar-name flex al-center jc-center'>{metal.symbol}</div>
          <div>{metal.name}</div>
        </IonCardSubtitle>
      </IonCardHeader>
      <IonCardContent className='content'>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <IonText>
            <h1>{usdFormatter.format(metal.current.market)}</h1>
          </IonText>
          <div>
            <DifferenceIndicator metal={metal} />
          </div>
        </div>
      </IonCardContent>
    </IonCard>
  );
};

MetalCard.propTypes = {
  metal: PropTypes.shape({
    current: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    previous: PropTypes.object.isRequired,
    symbol: PropTypes.string.isRequired,
  }).isRequired,
};
export default React.memo(MetalCard);
