import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {IonBackButton, IonButtons, IonContent, IonHeader, IonList, IonPage, IonSelectOption, IonTitle, IonToast, IonToolbar} from '@ionic/react';
import {close as closeIcon} from 'ionicons/icons';
import {FormikForm, SelectField, SubmitButton, TextareaField, TextField} from 'components';
import {object, string} from 'yup';
import {Firestore} from 'firebaseConfig';
import {chevronBack} from 'ionicons/icons';

const NeedHelpPage = () => {
  const history = useHistory();
  const {individual} = useSelector(state => state.supplier);

  return (
    <IonPage>
      <IonHeader className='ion-no-border'>
        <IonToolbar className='need-help-toolbar'>
          <IonButtons slot='start'>
            <IonBackButton className='back-btn' color='light' mode='ios' text='Settings' icon={chevronBack} defaultHref='/my/home/settings' />
          </IonButtons>
          <IonTitle>Need Help?</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className='ion-padding'>
        <FormikForm {...formProps(history, individual.data)}>{({status}) => (<>
          <IonList lines='full'>
            <TextField name='Name' ionInputProps={{readonly: true}} />
            <TextField name='Email' ionInputProps={{readonly: true}} />
            <TextField name='Phone' required />
            <SelectField name='PreferredContact' label='Preferred Contact' required>
              <IonSelectOption value='Email'>Email</IonSelectOption>
              <IonSelectOption value='Phone'>Phone</IonSelectOption>
            </SelectField>
            <SelectField name='Reason' required>
              <IonSelectOption value='Sales Question'>Sales Question</IonSelectOption>
              <IonSelectOption value='Technical Support'>Technical Support</IonSelectOption>
            </SelectField>
            <TextareaField name='Message' ionTextareaProps={{rows: 5}} required />
          </IonList>
          <SubmitButton expand='block'>Send</SubmitButton>
          <IonToast
            color={status?.color}
            duration={status?.duration}
            isOpen={status?.isOpen}
            message={status?.message}
            onDidDismiss={status?.onDidDismiss}
            buttons={[{side: 'end', icon: closeIcon, role: 'cancel'}]}
          />
        </>)}
        </FormikForm>
      </IonContent>
    </IonPage>
  );
};

const initialStatus = {color: '', duration: 0, isOpen: false, message: '', onDidDismiss: () => {}};
const formProps = (history, individual) => ({
  initialStatus,
  initialValues: {
    Name: individual?.Name?.Full || '',
    Email: individual?.Email || '',
    Phone: individual?.Phone || '',
    PreferredContact: 'Phone',
    Reason: 'Sales Question',
    Message: 'How do I perform a search?',
  },
  validationSchema: object().shape({
    Name: string().required(),
    Email: string().email().required(),
    Phone: string().required(),
    PreferredContact: string().label('Preferred Contact').required(),
    Reason: string().required(),
    Message: string().required(),
  }),
  onSubmit: (values, actions) => {
    (async () => {
      try {
        const collection = Firestore.collection('Emails');
        Firestore.addDoc(collection, {
          to: 'daniel@kbirecycling.com',
          template: {
            name: 'HelpRequestExternal',
            data: {...values, UserStatus: 'Existing User'},
          },
        });
        const message = 'Great! Your help request was sent. One of our reps will be in touch shortly.';
        const onDidDismiss = () => {
          actions.setStatus(initialStatus);
          history.push('/my/home');
          actions.setSubmitting(false);
        };
        actions.setStatus({color: 'success', duration: 6000, isOpen: true, message, onDidDismiss});
      }
      catch (error) {
        const message = error.message;
        const onDidDismiss = () => {
          actions.setStatus(initialStatus);
          actions.setSubmitting(false);
        };
        actions.setStatus({color: 'danger', duration: 5000, isOpen: true, message, onDidDismiss});
      }
    })();
  },
});
export default NeedHelpPage;
