import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonLabel, IonPage, IonSegment, IonSegmentButton, IonTitle, IonToolbar} from '@ionic/react';
import {add as addIcon} from 'ionicons/icons';
import {useRatings} from 'hooks';
import Placeholder from './Placeholder';
import OpenRatings from './OpenRatings';
import ClosedRatings from './ClosedRatings';

const ViewPhotoRatingPage = ({location}) => {
  const [segment, setSegment] = useState('open');
  const {openRatings, closedRatings} = useRatings();

  useEffect(() => {
    // If user just created a new rating, set segment to 'open' so they can see new rating
    if (location?.state?.fromNew) setSegment('open');
  }, [location.state]);

  return (
    <IonPage>
      <IonHeader className='ion-no-border'>
        <IonToolbar className='photo-grading-toolbar'>
          <IonTitle>Photo Rating</IonTitle>
          <IonButtons slot='end'>
            <IonButton color='light' routerLink='/my/photo-rating/new'>
              <IonIcon slot='icon-only' icon={addIcon} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonSegment className='segment-holder' value={segment} onIonChange={e => setSegment(e.detail.value)}>
          <IonSegmentButton value='open'>
            <IonLabel>Open</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value='closed'>
            <IonLabel>Closed</IonLabel>
          </IonSegmentButton>
        </IonSegment>
        <div className='ion-padding'>
          {segment === 'open' && (<>
            <Placeholder data={openRatings} segment={segment} />
            <OpenRatings ratings={openRatings} />
          </>)}
          {segment === 'closed' && (<>
            <Placeholder data={closedRatings} segment={segment} />
            <ClosedRatings ratings={closedRatings} />
          </>)}
        </div>
      </IonContent>
    </IonPage>
  );
};

ViewPhotoRatingPage.propTypes = {
  location: PropTypes.object.isRequired,
};
export default ViewPhotoRatingPage;
