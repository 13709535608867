import {batch, useDispatch, useSelector} from 'react-redux';
import {useEffect} from 'react';
import {ratingsActions} from 'state/ratings/slice';
import {Auth, Firestore} from 'firebaseConfig';
const snapOptions = {includeMetadataChanges: true};

const useRatings = () => {
  const ratings = useSelector(state => state.ratings);
  const dispatch = useDispatch();
  const currentUser = Auth.currentUser();

  useEffect(() => {
    if (!ratings.touched) {
      const dataMap = {};
      // Set touched to true; Prevents duplicate invocation of listener;
      dispatch(ratingsActions.setTouched(true));
      const snapData = snap => {
        const docChangeSize = snap.docChanges().length;
        // If collection is empty and redux state is loading, then set loading to false to show first snap performed
        if (docChangeSize === 0 && ratings.pending) dispatch(ratingsActions.setPending(false));
        // If 1 or more doc change in snap, then loop through changes and dispatch to redux state
        else if (docChangeSize >= 1) {
          for (let i = 0; i < docChangeSize; i++) {
            const {doc, type} = snap.docChanges()[i];
            // if doc.type is 'removed', then delete from dataMap object
            if (type === 'removed') delete dataMap[doc.id];
            // else add to or update on dataMap object
            else {
              const docData = doc.data();
              dataMap[doc.id] = {
                ...docData,
                DocId: doc.id,
                Closed: !docData.Closed ? null : {
                  ...docData.Closed,
                  On: docData?.Closed?.On ? docData.Closed.On.toMillis() : null,
                },
                Submitted: {
                  ...docData.Submitted,
                  On: docData?.Submitted?.On ? docData.Submitted.On.toMillis() : null,
                },
              };
            }
          }
          const data = {open: [], closed: []};
          Object.values(dataMap).forEach(doc => {
            if (doc.Status === 'Open') data.open.push(doc);
            if (doc.Status === 'Closed') data.closed.push(doc);
          });
          batch(() => {
            dispatch(ratingsActions.setOpen(data.open));
            dispatch(ratingsActions.setClosed(data.closed));
          });
        }
      };
      const snapError = error => {
        console.log(error.message, {error});
      };
      const query = Firestore.query(Firestore.collection('Photo-Ratings'), Firestore.where('Submitted.ByUid', '==', currentUser.uid));
      Firestore.onSnapshot(query, snapOptions, snapData, snapError);
    }
  }, [dispatch, currentUser.uid, ratings.pending, ratings.touched]);

  return {openRatings: ratings.open, closedRatings: ratings.closed};
};

export default useRatings;
