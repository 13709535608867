import {IonRouterOutlet} from '@ionic/react';
import {Redirect, Route} from 'react-router-dom';
import {useAuthStatus} from 'hooks';

// Import Page Components
import LoginPage from './LoginPage';
import RequestAccountPage from './RequestAccountPage';
import RequestReceivedPage from './RequestReceivedPage';
import ForgotPasswordPage from './ForgotPasswordPage';
import NeedHelpPage from './NeedHelpPage';

const AuthStack = () => {
  // AuthStack should only display if user is not authenticated;
  // If authenticated user navigates to AuthStack, redirect to main App homepage instead
  const {currentUser} = useAuthStatus();
  if (currentUser) return <Redirect to='/my/home' />;

  return (
    <IonRouterOutlet>
      <Route exact path='/auth/login' component={LoginPage} />
      <Route exact path='/auth/request-account' component={RequestAccountPage} />
      <Route exact path='/auth/request-received' component={RequestReceivedPage} />
      <Route exact path='/auth/forgot-password' component={ForgotPasswordPage} />
      <Route exact path='/auth/need-help' component={NeedHelpPage} />
      <Route render={() => <Redirect to={'/auth/login'} />} />
    </IonRouterOutlet>
  );
};

export default AuthStack;
