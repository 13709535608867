import React from 'react';
import PropTypes from 'prop-types';
import {IonItem, IonLabel, IonText, IonTextarea} from '@ionic/react';
import {useFormikContext} from 'formik';

const TextareaField = (props) => {
  const {disabled, ionTextareaProps, ionLabelProps, label, name, required, type} = props;
  const formik = useFormikContext();

  return (
    <IonItem className='input-item'>
      <IonLabel position='stacked' {...ionLabelProps}>
        {label || name}{required ? ' *' : ''}
      </IonLabel>
      <IonTextarea
        autoGrow={true}
        name={name}
        type={type}
        value={formik.values[name]}
        disabled={disabled || formik.isValidating || formik.isSubmitting}
        onIonChange={formik.handleChange}
        {...ionTextareaProps}
      />
      {formik?.errors && formik?.errors?.[name] && (
        <IonText color='danger' slot='helper'>
          {formik.errors[name]}
        </IonText>
      )}
    </IonItem>
  );
};

TextareaField.propTypes = {
  disabled: PropTypes.bool,
  ionTextareaProps: PropTypes.object,
  ionLabelProps: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
};
TextareaField.defaultProps = {
  disabled: false,
  ionInputProps: {},
  ionLabelProps: {},
  label: null,
  required: false,
  type: 'text',
};
export default React.memo(TextareaField);
