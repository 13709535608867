import {IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs} from '@ionic/react';
import {Redirect, Route} from 'react-router-dom';
import {PageLoader} from 'components';
import {useAuthStatus, useMarket, useSupplier} from 'hooks';

// Import Page Components
import {HomePage, NeedHelpPage, NotificationsPage, SettingsPage, UpdatePasswordPage, AdjustMarginPage} from './HomeStack';
import {UnitSearchPage, SearchResultsPage, UnitDetailsPage} from './CodeLookupStack';
import {ViewPhotoRatingPage, RatingDetails, NewPhotoRatingPage} from './PhotoRatingStack';

const AppTabs = () => {
  const {currentUser} = useAuthStatus();
  const {marketLoading} = useMarket();
  const {supplierLoading} = useSupplier(currentUser);
  // AppTabs should only display if user is authenticated; If unauthenticated user navigates to AppTabs, redirect to main App login instead
  if (!currentUser) return <Redirect to='/auth/login' />;

  if (supplierLoading || marketLoading) return <PageLoader />;
  return (
    <IonTabs>
      <IonRouterOutlet>
        <Route exact path='/my/home' component={HomePage} />
        <Route exact path='/my/home/notifications' component={NotificationsPage} />
        <Route exact path='/my/home/settings' component={SettingsPage} />
        <Route exact path='/my/home/settings/update-password' component={UpdatePasswordPage} />
        <Route exact path='/my/home/settings/adjust-margin' component={AdjustMarginPage} />
        <Route exact path='/my/home/settings' component={SettingsPage} />
        <Route exact path='/my/home/settings/need-help' component={NeedHelpPage} />
        <Route exact path='/my/unit-search' component={UnitSearchPage} />
        <Route exact path='/my/unit-search/results' component={SearchResultsPage} />
        <Route exact path='/my/unit-search/results/unit-details/:DocId' component={UnitDetailsPage} />
        <Route exact path='/my/photo-rating' component={ViewPhotoRatingPage} />
        <Route exact path='/my/photo-rating/new' component={NewPhotoRatingPage} />
        <Route exact path='/my/photo-rating/details/:DocId' component={RatingDetails} />
        <Route render={() => <Redirect to={'/my/home'} />} />
      </IonRouterOutlet>
      <IonTabBar slot='bottom'>
        <IonTabButton tab='home' href='/my/home'>
          <IonLabel>Home</IonLabel>
        </IonTabButton>
        <IonTabButton tab='unit-search' href='/my/unit-search'>
          <IonLabel>Unit Search</IonLabel>
        </IonTabButton>
        <IonTabButton tab='photo-rating' href='/my/photo-rating'>
          <IonLabel>Photo Rating</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

export default AppTabs;
