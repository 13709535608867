import {useState} from 'react';
import PropTypes from 'prop-types';
import {IonRange, IonText} from '@ionic/react';
const usdFormatter = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'});

const PriceRange = ({result}) => {
  const [rangeValue, setRangeValue] = useState(1);
  const rangeDescriptor = (rangeValue) => {
    if (rangeValue === 1) return 'Full';
    else if (rangeValue === 0.75) return 'Three Quarters';
    else if (rangeValue === 0.5) return 'Half';
    else if (rangeValue === 0.25) return 'Quarter';
  };

  return (<>
    <div className='range-holder ion-padding-top'>
      <IonText className='price-range-text'>
        <h4>{usdFormatter.format(result.UnitValue * rangeValue)}</h4>
      </IonText>
    </div>
    <div>
      <IonRange mode='ios' step={0.25} min={0.25} max={1} snaps={true} value={rangeValue} onIonChange={e => setRangeValue(e.detail.value)} />
      <IonText className='ion-text-center'><h6>{rangeDescriptor(rangeValue)}</h6></IonText>
    </div>
  </>);
};

PriceRange.propTypes = {result: PropTypes.object.isRequired};
export default PriceRange;
