import {Formik, Form} from 'formik';
import PropTypes from 'prop-types';

const FormikForm = props => {
  const {children, ...otherProps} = props;
  return (<Formik validateOnChange={false} {...otherProps}>
    {formikProps => (
      <Form style={{width: '100%'}} noValidate method="post">
        {children && children({...formikProps})}
      </Form>
    )}
  </Formik>);
};

FormikForm.propTypes = {children: PropTypes.func.isRequired};
export default FormikForm;
