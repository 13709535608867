import {IonAvatar, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonPage, IonText, IonTitle, IonToolbar} from '@ionic/react';
import {IonCard, IonCardContent, IonCardHeader, IonCardTitle} from '@ionic/react';
import {notificationsOutline as notificationsIcon, settingsOutline as settingsIcon} from 'ionicons/icons';
import {useSelector} from 'react-redux';
import {MetalCard} from 'components';

const HomePage = () => {
  const {metalValue} = useSelector(state => state.market);
  const {individual} = useSelector(state => state.supplier);
  const userFirstName = individual?.data?.Name?.First || 'Buyer';

  return (
    <IonPage>
      <IonHeader className='ion-no-border'>
        <IonToolbar className='welcome-toolbar'>
          <IonAvatar slot='start'>
            <img src='assets/imgs/avatar.png' alt='User avatar' />
          </IonAvatar>
          <IonTitle>Welcome {userFirstName}</IonTitle>
          <IonButtons slot='primary'>
            <IonButton routerLink='/my/home/notifications'>
              <IonIcon slot='icon-only' icon={notificationsIcon} />
            </IonButton>
            <IonButton routerLink='/my/home/settings'>
              <IonIcon slot='icon-only' icon={settingsIcon} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className='home'>
        <div className='fake-bar'>
        </div>
        <IonCard className='first-card ion-padding'>
          <IonCardHeader>
            <IonCardTitle className='card-header'>Converter Search</IonCardTitle>
          </IonCardHeader>
          <IonCardContent class='card-content'>
            Find the details of a specific converter and see how much its worth to you and your suppliers.
          </IonCardContent>
          <IonButton className='search-btn' expand='block' fill='solid' routerLink='/my/unit-search'>Start Search</IonButton>
        </IonCard>
        <div className='rest-content ion-padding-horizontal'>
          <IonText className='rest-main'>
            <h4>Current Metal Pricing</h4>
          </IonText>
          <IonText className='rest-sub'>
            <p>Last update on {metalValue?.dateUpdated || ''}</p>
          </IonText>
          <div className='ion-padding-top'>
            <MetalCard metal={metalValue.pt} />
            <MetalCard metal={metalValue.pd} />
            <MetalCard metal={metalValue.rh} />
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default HomePage;
