import {Redirect, Route, Switch} from 'react-router-dom';
import {PageLoader} from 'components';
import {useAuthStatus} from 'hooks';

// Import Root-Level Page Components
import AuthStack from 'pages/AuthStack';
import AppTabs from 'pages/AppTabs';
import {ResetPasswordPage} from 'pages/Global';

const App = (props) => {
  const {authLoading, currentUser, signoutPending} = useAuthStatus();

  if (authLoading || signoutPending) return <PageLoader />;
  return (
    <Switch>
      <Route path='/auth' component={AuthStack} />
      <Route path='/__/auth/action' component={ResetPasswordPage} />
      <Route path='/my' component={AppTabs} />
      <Route render={() => <Redirect to={currentUser ? '/my' : '/auth'} />} />
    </Switch>
  );
};

export default App;
