import {initializeApp} from 'firebase/app';
import {getAuth, confirmPasswordReset, onAuthStateChanged, sendPasswordResetEmail, signInWithEmailAndPassword, signOut, updateEmail, updateProfile, verifyPasswordResetCode} from 'firebase/auth';
import {getFirestore, addDoc, collection, doc, getDoc, getDocs, onSnapshot, query, updateDoc, where} from 'firebase/firestore';
import {getFunctions, httpsCallable} from 'firebase/functions';

const Fire = initializeApp({
  apiKey: 'AIzaSyCuEN7WL2vHwzwXirp5SdeF5E_rdWurrtg',
  authDomain: 'kbi-catbuyer.firebaseapp.com',
  projectId: 'kbi-catbuyer',
  storageBucket: 'kbi-catbuyer.appspot.com',
  messagingSenderId: '48987118538',
  appId: '1:48987118538:web:de77d047524c5b0f130d33',
  measurementId: 'G-2HP6C7N03B',
});

// Initialize Firebase Authentication
const AuthInit = getAuth(Fire);
const Auth = {
  confirmPasswordReset: (code, newPassword) => confirmPasswordReset(AuthInit, code, newPassword),
  currentUser: () => AuthInit.currentUser,
  onAuthStateChanged: cb => onAuthStateChanged(AuthInit, cb),
  sendPasswordResetEmail: (email) => sendPasswordResetEmail(AuthInit, email),
  signInWithEmailAndPassword: (email, password) => signInWithEmailAndPassword(AuthInit, email, password),
  signOut: () => signOut(AuthInit),
  updateEmail: email => updateEmail(AuthInit.currentUser, email),
  updateProfile: profile => updateProfile(AuthInit.currentUser, profile),
  verifyPasswordResetCode: code => verifyPasswordResetCode(AuthInit, code),
};

// Initialize Firebase Firestore
const FirestoreInit = getFirestore(Fire);
const Firestore = {
  addDoc,
  collection: collectionPath => collection(FirestoreInit, collectionPath),
  doc: param => {
    // When param type is a Firestore collection, passing FirestoreInit will cause system crash (Init already passed to collection);
    if (param?.type === 'collection') return doc(param);
    // Otherewise pass FirestoreInit to properly initialize doc module;
    return doc(FirestoreInit, param);
  },
  getDoc,
  getDocs,
  onSnapshot,
  query,
  updateDoc,
  where,
};

// Initialize Firebase Functions
const FunctionsInit = getFunctions(Fire);
// Uncomment line below and add connectFunctionsEmulator to 'firebase/functions' import to test functions locally
// connectFunctionsEmulator(FunctionsInit, 'localhost', 5001);
const Functions = {
  httpsCallable: functionName => httpsCallable(FunctionsInit, functionName),
};

export {Auth, Firestore, Functions};
