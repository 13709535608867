import {IonButton, IonContent, IonList, IonPage, IonRouterLink, IonText, IonToast} from '@ionic/react';
import {close as closeIcon} from 'ionicons/icons';
import {FormikForm, SubmitButton, TextField} from 'components';
import {object, string} from 'yup';
import {Auth} from 'firebaseConfig';

const LoginPage = () => {
  return (
    <IonPage>
      <IonContent className='ion-padding'>
        <div className='logo-holder ion-text-center'>
          <img src="assets/imgs/logo.svg" alt='Catbuyer logo' />
        </div>
        <FormikForm {...formProps}>{({status}) => (<>
          <IonList lines='full'>
            <TextField className="input-field" required name='Email' ionInputProps={{inputmode: 'email'}} />
            <TextField className="input-field" required name='Password' type='password' />
          </IonList>
          <div className='forgot-holder ion-text-right'>
            <IonRouterLink routerLink='/auth/forgot-password'>Forgot Password?</IonRouterLink>
          </div>
          <SubmitButton className='submit-btn' expand='block'>Login</SubmitButton>
          <IonButton className='help-btn' expand='block' fill='clear' routerLink='/auth/need-help'>Need help?</IonButton>
          <IonToast
            color={status?.color}
            duration={status?.duration}
            isOpen={status?.isOpen}
            message={status?.message}
            onDidDismiss={status?.onDidDismiss}
            buttons={[{side: 'end', icon: closeIcon, role: 'cancel'}]}
          />
        </>)}
        </FormikForm>
        <IonButton className='req-btn' expand='block' routerLink='/auth/request-account'>Request Account</IonButton>
        <div className='pp-holder'>
          <IonText className='privacy-policy ion-text-center' >
            <p>&copy; {new Date().getFullYear()} All Rights Reserved.</p>
            <p>Check our <span>Privacy Policy</span> and  <span>Terms</span></p>
          </IonText>
        </div>
      </IonContent>
    </IonPage>
  );
};

const initialStatus = {color: '', duration: 0, isOpen: false, message: '', onDidDismiss: () => {}};
const formProps = {
  initialStatus,
  initialValues: {
    Email: 'daniel@kbirecycling.com',
    Password: 'DevPassword!',
  },
  validationSchema: object().shape({
    Email: string().email().required(),
    Password: string().required().min(6),
  }),
  onSubmit: (values, actions) => {
    (async () => {
      try {
        await Auth.signInWithEmailAndPassword(values.Email, values.Password);
      }
      catch (error) {
        actions.setSubmitting(false);
        const message = (() => {
          if (error.code === 'auth/user-disabled') return 'User account has been disabled.';
          else if (error.code === 'auth/invalid-email') return 'Invalid email address provided.';
          else if (error.code === 'auth/user-not-found') return 'Incorrect username or password provided.';
          else if (error.code === 'auth/wrong-password') return 'Incorrect username or password provided.';
          else return error.message;
        })();
        const onDidDismiss = () => actions.setStatus(initialStatus);
        actions.setStatus({color: 'danger', duration: 5000, isOpen: true, message, onDidDismiss});
      }
    })();
  },
};
export default LoginPage;
