import {IonButton} from '@ionic/react';
import PropTypes from 'prop-types';
import {useFormikContext} from 'formik';

const SubmitButton = props => {
  const {children, ...parentProps} = props;
  const formik = useFormikContext();

  const buttonProps = {
    ...parentProps,
    disabled: parentProps.disabled || formik.isSubmitting || formik.isValidating,
  };

  return <IonButton className='submit-btn' type='submit' {...buttonProps}>{children || 'Submit'}</IonButton>;
};

SubmitButton.propTypes = {
  children: PropTypes.node,
};
export default SubmitButton;
