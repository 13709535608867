import {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Functions} from 'firebaseConfig';
import {marketActions} from 'state/market/slice';
const ONE_HOUR_MS = 3600000;
const TWENTY_FOUR_HOURS_MS = ONE_HOUR_MS * 24;

const useMarket = () => {
  const {leaseRate, metalValue} = useSelector(state => state.market);
  const dispatch = useDispatch();

  const getLeaseRate = useCallback(async () => {
    const {data: payload} = await Functions.httpsCallable('marketGetLeaseRate')();
    dispatch(marketActions.setLeaseRate(payload));
  }, [dispatch]);

  const getMetalValue = useCallback(async () => {
    const {data: payload} = await Functions.httpsCallable('marketGetMetalValue')();
    dispatch(marketActions.setMetalValue(payload));
  }, [dispatch]);

  useEffect(() => {
    // Get lease rate and metal value data as soon as App mounts
    getLeaseRate();
    getMetalValue();

    // Refresh lease rate data after twenty-four hours
    setInterval(() => getLeaseRate(), TWENTY_FOUR_HOURS_MS);

    // Refresh metal value data after one hour
    setInterval(() => getMetalValue(), ONE_HOUR_MS);
  }, [getLeaseRate, getMetalValue]);

  // Market is no longer loading when both lease rate and metal values values have been set in Redux
  const marketLoading = !Boolean(leaseRate && metalValue);

  return {marketLoading};
};

export default useMarket;
