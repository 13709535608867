import {useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import {IonBackButton, IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonPage, IonRow, IonTitle, IonToast, IonToolbar} from '@ionic/react';
import {close as closeIcon, chevronBack} from 'ionicons/icons';
import {FormikForm, SubmitButton, TextareaField} from 'components';
import {Functions} from 'firebaseConfig';
import {object, string} from 'yup';
import usePhotoGallery from './usePhotoGallery';
import PhotoPlaceholder from './PhotoPlaceholder';
import PhotoDisplay from './PhotoDisplay';

const NewPhotoRatingPage = () => {
  const history = useHistory();
  const {photos, takePhoto, deletePhoto, clearPhotos} = usePhotoGallery();
  const onFormikSubmit = useCallback((values, actions) => {
    (async () => {
      try {
        // Throw error if user has not added the two pictures of the converter being rated
        if (photos.length < 1) throw new Error('Please take at least one photo.');

        const photoData = photos.map(photo => ({base64String: photo.base64String, format: photo.format}));
        await Functions.httpsCallable('ratingSubmitRequest')({values, photoData});

        const message = 'Rating request has been submitted. One of our reps will respond shortly.';
        const onDidDismiss = () => {
          history.push({pathname: '/my/photo-rating', state: {fromNew: true}});
          actions.resetForm();
          clearPhotos();
        };
        actions.setStatus({color: 'success', duration: 5000, isOpen: true, message, onDidDismiss});
      }
      catch (error) {
        const message = error.message;
        const onDidDismiss = () => {
          actions.setStatus(initialStatus);
          actions.setSubmitting(false);
        };
        actions.setStatus({color: 'danger', duration: 5000, isOpen: true, message, onDidDismiss});
      }
    })();
  }, [photos, history, clearPhotos]);

  return (
    <IonPage>
      <IonHeader className='ion-no-border'>
        <IonToolbar className='new-grading-toolbar'>
          <IonButtons slot='start'>
            <IonBackButton className='back-btn' color='light' mode='ios' text='Photo Grading' icon={chevronBack} defaultHref='/my/photo-rating' />
          </IonButtons>
          <IonTitle>New Grading</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className='ion-padding'>
        <IonGrid>
          <PhotoPlaceholder photos={photos} />
          <PhotoDisplay photos={photos} deletePhoto={deletePhoto} />
          <IonRow>
            <IonCol size='12'>
              <IonButton className='take-photo-btn' fill='solid' expand='block' onClick={takePhoto} disabled={photos.length >= 2}>
                {photos.length < 2 ? 'Take Photos' : 'Max Photos Taken'}
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
        <FormikForm {...formProps} onSubmit={onFormikSubmit}>{({status}) => (<>
          <TextareaField {...textareaProps} />
          <SubmitButton expand='block'>Submit Request</SubmitButton>
          <IonToast
            color={status?.color}
            duration={status?.duration}
            isOpen={status?.isOpen}
            message={status?.message}
            onDidDismiss={status?.onDidDismiss}
            buttons={[{side: 'end', icon: closeIcon, role: 'cancel'}]}
          />
        </>)}
        </FormikForm>
      </IonContent>
    </IonPage>
  );
};

const initialStatus = {color: '', duration: 0, isOpen: false, message: '', onDidDismiss: () => {}};
const formProps = {
  initialStatus,
  initialValues: {Details: ''},
  validationSchema: object().shape({
    Details: string().label('Visual Details').required(),
  }),
};
const textareaProps = {
  ionTextareaProps: {
    placeholder: 'Include any visual details (e.g. part number, manufacturer, etc.) ',
    rows: 5,
  },
  label: 'Visual Details',
  name: 'Details',
  required: true,
};
export default NewPhotoRatingPage;
