import React from 'react';
import PropTypes from 'prop-types';
import {IonInput, IonItem, IonLabel, IonText} from '@ionic/react';
import {useFormikContext} from 'formik';

const TextField = (props) => {
  const {disabled, ionInputProps, ionLabelProps, label, name, required, type} = props;
  const formik = useFormikContext();

  return (
    <IonItem lines='none' className='input-item'>
      <IonLabel position='stacked' {...ionLabelProps}>
        {label || name}{required ? ' *' : ''}
      </IonLabel>
      <IonInput
        name={name}
        type={type}
        value={formik.values[name]}
        disabled={disabled || formik.isValidating || formik.isSubmitting}
        onIonChange={formik.handleChange}
        {...ionInputProps}
      />
      {formik?.errors && formik?.errors?.[name] && (
        <IonText color='danger' slot='helper'>
          {formik.errors[name]}
        </IonText>
      )}
    </IonItem>
  );
};

TextField.propTypes = {
  disabled: PropTypes.bool,
  ionInputProps: PropTypes.object,
  ionLabelProps: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
};
TextField.defaultProps = {
  disabled: false,
  ionInputProps: {},
  ionLabelProps: {},
  label: null,
  required: false,
  type: 'text',
};
export default React.memo(TextField);
