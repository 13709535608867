import React from 'react';
import PropTypes from 'prop-types';
import {IonItem, IonLabel, IonSelect, IonText} from '@ionic/react';
import {useFormikContext} from 'formik';

const SelectField = (props) => {
  const {disabled, ionSelectProps, ionLabelProps, label, name, required} = props;
  const formik = useFormikContext();

  return (
    <IonItem className='input-item'>
      <IonLabel position='stacked' {...ionLabelProps}>
        {label || name}{required ? ' *' : ''}
      </IonLabel>
      <IonSelect mode='ios'
        value={formik.values[name]}
        disabled={disabled || formik.isValidating || formik.isSubmitting}
        onIonChange={event => {
          formik.setFieldValue(name, event.detail.value);
          formik.setFieldTouched(name, true, false);
          formik.setFieldError(name, '');
        }}
        {...ionSelectProps}
      >
        {props.children}
      </IonSelect>
      {formik?.errors && formik?.errors?.[name] && (
        <IonText color='danger' slot='helper'>
          {formik.errors[name]}
        </IonText>
      )}
    </IonItem>
  );
};

SelectField.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  ionLabelProps: PropTypes.object,
  ionSelectProps: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
};
SelectField.defaultProps = {
  disabled: false,
  ionLabelProps: {},
  ionSelectProps: {},
  label: null,
  required: false,
};
export default React.memo(SelectField);
