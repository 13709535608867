import {useState} from 'react';
import {IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonLabel, IonPage, IonRange, IonText, IonTitle, IonToggle, IonToolbar} from '@ionic/react';
import {chevronBack} from 'ionicons/icons';

const AdjustMarginPage = () => {
  const [marginActive, setMarginActive] = useState(false);
  const [marginValue, setMarginValue] = useState(0);
  const handleFeatureToggle = (e) => {
    if (!e.detail.checked) setMarginValue(0);
    setMarginActive(e.detail.checked);
  };

  return (
    <IonPage>
      <IonHeader className='ion-no-border'>
        <IonToolbar className='adjust-margin-toolbar'>
          <IonButtons slot='start'>
            <IonBackButton className='back-btn' color='light' mode='ios' text='Settings' icon={chevronBack} defaultHref='/my/home/settings' />
          </IonButtons>
          <IonTitle>Margin</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className='adjust-margin ion-padding'>
        <IonText className='main-title'>
          <h1>Adjust custom margin</h1>
        </IonText>
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <IonLabel className='description' style={{maxWidth: '80%'}}>Enable feature to apply custom margin to unit pricing.</IonLabel>
          <IonToggle mode='ios' checked={marginActive} onIonChange={handleFeatureToggle} />
        </div>
        <IonText>
          <h1 className='marginn' style={{textAlign: 'center'}}>{marginValue}%</h1>
        </IonText>
        <IonRange mode='ios' min={0} max={100} value={marginValue} onIonChange={e => setMarginValue(e.detail.value)} disabled={!marginActive}>
          <IonLabel slot='start'>0</IonLabel>
          <IonLabel slot='end'>100</IonLabel>
        </IonRange>
        <IonText>
          <p className='description'>
            Use the slider above to control how much margin you want to keep in your unit pricing.
            If a unit is price at $200 and you want to keep 10% margin, set the slider to 10%. This will
            make the unit price $180.
          </p>
        </IonText>
        <IonButton className='submit-btn' expand='block'>Submit</IonButton>
      </IonContent>
    </IonPage>
  );
};

export default AdjustMarginPage;
