import {configureStore} from '@reduxjs/toolkit';

// Import redux reducers
import AuthReducer from './auth/slice';
import MarketReducer from './market/slice';
import RatingsReducer from './ratings/slice';
import SearchReducer from './search/slice';
import SupplierReducer from './supplier/slice';

// Initialize Redux Store
const store = configureStore({
  reducer: {
    auth: AuthReducer,
    market: MarketReducer,
    ratings: RatingsReducer,
    search: SearchReducer,
    supplier: SupplierReducer,
  },
});

export default store;
