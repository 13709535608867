import {IonContent, IonPage, IonSpinner} from '@ionic/react';

const PageLoader = () => (
  <IonPage>
    <IonContent>
      <IonSpinner name='dots' style={{width: '100%', marginTop: '50vh', transform: 'scale(2)'}} />
    </IonContent>
  </IonPage>
);

export default PageLoader;
