import {useEffect, useMemo, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonList, IonPage, IonText, IonToast, IonToolbar} from '@ionic/react';
import {close as closeIcon} from 'ionicons/icons';
import {FormikForm, SubmitButton, TextField} from 'components';
import {object, string} from 'yup';
import {Auth} from 'firebaseConfig';

const ResetPasswordPage = () => {
  const [toast, setToast] = useState('');
  const [resetCode, setResetCode] = useState('');
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    (async () => {
      try {
        const splitQueryParams = location.search.replace('?', '').split('&');
        const codeQueryParam = splitQueryParams.filter(param => param.startsWith('oobCode')).pop();
        const resetCode = codeQueryParam.split('=')[1];
        await Auth.verifyPasswordResetCode(resetCode);
        setResetCode(resetCode);
      }
      catch (error) {
        console.log({error});
        const message = (() => {
          if (error.code === 'auth/expired-action-code') return 'Provided reset code has expired.';
          else if (error.code === 'auth/invalid-action-code') return 'Provided reset code is invalid.';
          else if (error.code === 'auth/user-disabled') return 'User has been disabled by adminstrator.';
          else if (error.code === 'auth/user-not-found') return 'No user corresponds to provided reset code.';
          else return `An error occured: ${error.code}`;
        })();
        const onDidDismiss = () => history.push('/auth/login');
        setToast({color: 'danger', duration: 5000, isOpen: true, message, onDidDismiss});
      }
    })();
  }, [history, location.search]);
  const formProps = useMemo(() => ({
    initialValues: {
      NewPassword: 'test1234',
      ConfirmPassword: 'test1234',
    },
    validationSchema: object().shape({
      NewPassword: string().label('New Password').required().min(6),
      ConfirmPassword: string().label('Confirm Password').required().min(6).test(
        'is-match',
        'Confirm Password does not match New Password',
        (value, context) => context.parent.NewPassword === context.parent.ConfirmPassword,
      ),
    }),
    onSubmit: (values, actions) => {
      (async () => {
        try {
          await Auth.confirmPasswordReset(resetCode, values.NewPassword);
          const message = 'Password has been successfully reset.';
          const onDidDismiss = () => {
            setToast('');
            history.push('/auth/login');
            actions.setSubmitting(false);
          };
          setToast({color: 'success', duration: 5000, isOpen: true, message, onDidDismiss});
        }
        catch (error) {
          const message = (() => {
            if (error.code === 'auth/expired-action-code') return 'Password reset code has expired.';
            else if (error.code === 'auth/invalid-action-code') return 'Password reset code is invalid.';
            else if (error.code === 'auth/user-disabled') return 'User corresponding to reset has been disabled.';
            else if (error.code === 'auth/user-not-found') return 'No user found with this email address.';
            else if (error.code === 'auth/weak-password') return 'New password is not strong enough.';
            else return `An error occured: ${error?.code || error.message}`;
          })();
          const onDidDismiss = () => {
            setToast('');
            actions.setSubmitting(false);
          };
          setToast({color: 'danger', duration: 5000, isOpen: true, message, onDidDismiss});
        }
      })();
    },
  }), [history, resetCode]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonBackButton defaultHref={'/auth/login'} />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className='ion-padding'>
        <IonText className='the-title'>
          <h1>Create New Password</h1>
        </IonText>
        <FormikForm {...formProps}>{() => (<>
          <IonList lines='full'>
            <TextField required name='NewPassword' label='New Password' type='password' disabled={!resetCode} />
            <TextField required name='ConfirmPassword' label='Confirm Password' type='password' disabled={!resetCode} />
          </IonList>
          <SubmitButton expand='block'>Login</SubmitButton>
          <IonButton expand='block' fill='clear' routerLink='/auth/login'>Cancel</IonButton>
        </>)}
        </FormikForm>
        <IonToast
          color={toast.color}
          duration={toast.duration}
          isOpen={toast.isOpen}
          message={toast.message}
          onDidDismiss={toast.onDidDismiss}
          buttons={[{side: 'end', icon: closeIcon, role: 'cancel'}]}
        />
      </IonContent>
    </IonPage>
  );
};

export default ResetPasswordPage;
