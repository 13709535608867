import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  open: [],
  closed: [],
  pending: true,
  touched: false,
};

const ratingsSlice = createSlice({
  name: 'ratings',
  initialState,
  reducers: {
    setOpen: (state, action) => {
      state.open = action.payload;
    },
    setClosed: (state, action) => {
      state.closed = action.payload;
    },
    setPending: (state, action) => {
      state.pending = action.payload;
    },
    setTouched: (state, action) => {
      state.touched = action.payload;
    },
  },
});

export const ratingsActions = ratingsSlice.actions;

export default ratingsSlice.reducer;
