import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  leaseRate: null,
  metalValue: null,
};

const marketSlice = createSlice({
  name: 'market',
  initialState,
  reducers: {
    setLeaseRate: (state, action) => {
      state.leaseRate = action.payload;
    },
    setMetalValue: (state, action) => {
      state.metalValue = action.payload;
    },
  },
});

export const marketActions = marketSlice.actions;

export default marketSlice.reducer;
