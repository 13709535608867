import {IonButton, IonImg, IonRouterLink} from '@ionic/react';
import PropTypes from 'prop-types';
import {Firestore, Functions} from 'firebaseConfig';

const OpenRatings = ({ratings}) => {
  if (ratings.length === 0) return null;
  const handleDelete = async (rating) => {
    await Functions.httpsCallable('ratingDeleteRequest')({rating});
  };
  const handleClose = async (rating) => {
    const docRef = Firestore.doc(`Photo-Ratings/${rating.DocId}`);
    const ratingUpdate = {
      Closed: {
        By: 'Daniel Kinsbursky',
        On: new Date(),
      },
      Quote: 24,
      Status: 'Closed',
    };
    await Firestore.updateDoc(docRef, ratingUpdate);
  };

  return (<>
    {ratings.map(rating => {
      const openDate = new Date(rating.Submitted.On);
      return (
        <div style={styles.border} key={rating.DocId}>
          <ul>
            <li>Submitted: {openDate.toLocaleString('en-US')}</li>
            <li>Visual Details: {rating.Details}</li>
            <li>
              <IonRouterLink routerLink={`/my/photo-rating/details/${rating.DocId}`}>Details</IonRouterLink>
            </li>
          </ul>
          <IonImg src={rating.Images[0].ThumbnailUrl} />
          <IonButton size='small' onClick={() => handleDelete(rating)}>Delete_Dev</IonButton>
          <IonButton size='small' onClick={() => handleClose(rating)}>Close_Dev</IonButton>
        </div>
      );
    })}
  </>);
};

const styles = {
  border: {border: '1px solid gray'},
};
OpenRatings.propTypes = {ratings: PropTypes.array.isRequired};
export default OpenRatings;
