import {IonBackButton, IonButtons, IonContent, IonHeader, IonList, IonPage, IonTitle, IonToolbar} from '@ionic/react';
import {FormikForm, SubmitButton, TextField} from 'components';
import {object, string} from 'yup';
import {chevronBack} from 'ionicons/icons';

const UpdatePasswordPage = () => {
  return (
    <IonPage>
      <IonHeader className='ion-no-border'>
        <IonToolbar className='update-pass-toolbar'>
          <IonButtons slot='start'>
            <IonBackButton className='back-btn' color='light' mode='ios' text='Settings' icon={chevronBack} defaultHref='/my/home/settings' />
          </IonButtons>
          <IonTitle>Update Password</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className='ion-padding'>
        <FormikForm {...formProps}>{() => (<>
          <IonList lines='full'>
            <TextField required name='ExistingPass' label='Existing Password' type='password' />
            <TextField required name='NewPass' label='New Password' type='password' />
            <TextField required name='ConfirmNewPass' label='Confirm New Password' type='password' />
          </IonList>
          <SubmitButton expand='block'>Update</SubmitButton>
        </>)}
        </FormikForm>
      </IonContent>
    </IonPage>
  );
};

const formProps = {
  initialValues: {
    ExistingPass: '',
    NewPass: '',
    ConfirmNewPass: '',
  },
  validationSchema: object().shape({
    ExistingPass: string().label('Existing Password').required().min(6),
    NewPass: string().label('New Password').required().min(6),
    ConfirmNewPass: string().label('Confirm New Password').required().min(6),
  }),
  onSubmit: async (values, actions) => {
    try {
      const {ExistingPass, NewPass, ConfirmNewPass} = values;
      console.log(ExistingPass, NewPass, ConfirmNewPass);
    }
    catch (error) {
      console.log({error});
    }
  },
};
export default UpdatePasswordPage;
