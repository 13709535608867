import {useEffect} from 'react';
import {batch, useDispatch, useSelector} from 'react-redux';
import {Auth} from 'firebaseConfig';
import {authActions} from 'state/auth/slice';
let authUnsubscribe = null;

const useAuthStatus = () => {
  const dispatch = useDispatch();
  const {authLoading, currentUser, signoutPending} = useSelector(state => state.auth);

  useEffect(() => {
    // Only invoke Firebase auth listener if authUnsubscribe is falsey and authLoading is true; Prevents multiple invocations;
    if (!authUnsubscribe && authLoading) {
      // Setup Firebase auth listener
      authUnsubscribe = Auth.onAuthStateChanged(user => {
        // If no authenticated user, set currentUser null and prevent remainder of code from runnin
        if (!user) {
          batch(() => {
            dispatch(authActions.setAuthLoading(false));
            dispatch(authActions.setCurrentUser(null));
          });
          return;
        }
        // Else if user authenticated, set important user values to currentUser auth state
        const currentUser = {
          displayName: user.displayName,
          email: user.email,
          emailVerified: user.emailVerified,
          // metadata: user.metadata,
          uid: user.uid,
        };
        batch(() => {
          dispatch(authActions.setAuthLoading(false));
          dispatch(authActions.setCurrentUser(currentUser));
        });
      });
    }
  }, [dispatch, authLoading]);

  return {authLoading, currentUser, signoutPending};
};

export default useAuthStatus;
