import {useDispatch, useSelector} from 'react-redux';
import {useCallback} from 'react';
import {searchActions} from 'state/search/slice';

const useSearch = () => {
  const {results} = useSelector(state => state.search);
  const dispatch = useDispatch();

  const setResults = useCallback((payload) => {
    dispatch(searchActions.setResults(payload));
  }, [dispatch]);

  return {results, setResults};
};

export default useSearch;
