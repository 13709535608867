import {IonRow, IonCol, IonImg, IonButton} from '@ionic/react';
import PropTypes from 'prop-types';

const PhotoDisplay = ({photos, deletePhoto}) => {
  if (photos.length === 0) return null;
  return (<>
    {photos.map((photo, index) => (
      <IonRow key={photo.fileName}>
        <IonCol size='12'>
          <div style={styles.relative}>
            <IonImg src={photo.base64Src} />
            <IonButton color='secondary' size='small' onClick={() => deletePhoto(index)} style={styles.absolute}>X</IonButton>
          </div>
        </IonCol>
      </IonRow>
    ))}
  </>);
};

const styles = {
  relative: {position: 'relative'},
  absolute: {position: 'absolute', bottom: '4px', left: '4px'},
};
PhotoDisplay.propTypes = {
  photos: PropTypes.array.isRequired,
  deletePhoto: PropTypes.func.isRequired,
};
export default PhotoDisplay;
