import {useDispatch} from 'react-redux';
import {Auth} from 'firebaseConfig';
import {authActions} from 'state/auth/slice';

const useAuthSignOut = () => {
  const dispatch = useDispatch();

  return () => {
    // Setting signoutPending true will trigger PageLoader compaonent in App.js to render
    dispatch(authActions.setSignoutPending(true));

    // Trigger Firebase's sign out function
    Auth.signOut();

    // Trigger browser reload after sign out to completely clear Redux state and browser cache
    // Set 1 second timeout as minimum duration for PageLoader to be displayed; Less time may look buggy due to screen flash;
    setTimeout(() => window.location.reload(), 1000);
  };
};

export default useAuthSignOut;
