import PropTypes from 'prop-types';

const Placeholder = ({data, segment}) => {
  if (data.length !== 0) return null;
  return (
    <div className='empty-holder ion-text-center'>
      <img src="assets/imgs/tabicn/box.png" alt='Box icon' />
      <h5> You have no {segment} requests.</h5>
    </div>
  );
};

Placeholder.propTypes = {
  data: PropTypes.array.isRequired,
  segment: PropTypes.string.isRequired,
};
export default Placeholder;
